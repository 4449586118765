import GifLoader from 'three-gif-loader';

function NordkappPointcloud(main, singlesource = false) {
  this.geometry;
  this.material;
  this.sources = [];
  this.nearClips = [];
  this.farClips = [];
  this.textures = [];
  this.materials = [];
  this.meshes = [];

  this.init = function(){

    console.log('inited NordkappPointcloud');

      this.vs = 'uniform sampler2D map;uniform float width;uniform float height;uniform float nearClipping, farClipping;uniform float pointSize;uniform float zOffset;varying vec2 vUv;const float XtoZ = 1.11146;const float YtoZ = 0.83359;void main() {vUv = vec2( position.x / width, position.y / height );vec4 color = texture2D( map, vUv );float depth = ( color.r + color.g + color.b ) / 3.0;float z = ( 1.0 - depth ) * (farClipping - nearClipping) + nearClipping;vec4 pos = vec4(( position.x / width - 0.5 ) * z * XtoZ,( position.y / height - 0.5 ) * z * YtoZ,-z + zOffset,1.0);gl_PointSize = pointSize;gl_Position = projectionMatrix * modelViewMatrix * pos;}';

      this.fs = 'uniform sampler2D map;uniform float colorIntensity;varying vec2 vUv;void main() {vec4 color = texture2D( map, vUv );gl_FragColor = vec4( color.r, color.g, color.b, colorIntensity );}';

    /*
    this.intro = document.getElementById( 'video-intro' );
    this.strategy = document.getElementById( 'video-strategy' );
    */

    /*
    this.sources = [
      document.getElementById( 'video-intro' ),
      document.getElementById( 'video-strategy' ),
    ];
    */
    //console.log($('article video').length);
    let self = this;

    if(!singlesource){
      
      $('.pointcloud-video video, .pointcloud-gif img').each(function(idx){
        // console.log(this);
        if($(this).attr('src')){
          self.sources[idx] = this;
          self.nearClips[idx] = parseInt($(this).attr('data-near')) || 300;
          self.farClips[idx] = parseInt($(this).attr('data-far')) || 3500;
          this.playbackRate = 0.75;
          self.sources[idx].isVideo = $(this).is('video');
        } else if ($(this).find('source').length>0) {
          self.sources[idx] = this;
          self.nearClips[idx] = parseInt($(this).attr('data-near')) || 300;
          self.farClips[idx] = parseInt($(this).attr('data-far')) || 3500;
          this.playbackRate = 0.75;
          self.sources[idx].isVideo = $(this).is('video');
        }
      });
    } else {
      self.sources[0] = singlesource.get(0);
      self.nearClips[0] = parseInt($(singlesource).attr('data-near')) || 300;
      self.farClips[0] = parseInt($(singlesource).attr('data-far')) || 3500;
      self.sources[0].isVideo = $(singlesource).is('video');
      if($(singlesource).is('video')) {
        $(singlesource).get(0).playbackRate = 0.75;
      }
      console.log(self, singlesource);
    }

    for(var i=0;i<this.sources.length;i++){
      if(this.sources[i].isVideo) {
        this.textures[i] = new THREE.VideoTexture( this.sources[i] );
        this.textures[i].minFilter = THREE.NearestFilter;
      } else {
        var imgsrc = $(this.sources[i]).attr('src');
        if(imgsrc.indexOf('.gif')>0) {
          // instantiate a loader
          const loader = new GifLoader();
          this.textures[i] = loader.load(
            // resource URL
            imgsrc,

            // onLoad callback
            function (reader) {
              console.log(reader.numFrames());
              $(window).trigger('scroll');
            },

            // onProgress callback
            function (xhr) {
              console.log(`${(xhr.loaded / xhr.total * 100)}% loaded`);
            },

            // onError callback
            function () {
              console.error('An error happened.');
            }
          );
        } else {
          // TODO just image texture
        }
        this.textures[i].minFilter = THREE.NearestFilter;
      }
    }

    const width = window.innerWidth;
    const height = window.innerHeight;

    this.geometry = new THREE.BufferGeometry();

    const vertices = new Float32Array( width * height * 3 );

    for ( let i = 0, j = 0, l = vertices.length; i < l; i += 3, j ++ ) {

      vertices[ i ] = j % width;
      vertices[ i + 1 ] = Math.floor( j / width );

    }

    //  console.log('nearclips', this.nearClips);
    //  console.log('farclips', this.farClips);

    this.geometry.setAttribute( 'position', new THREE.BufferAttribute( vertices, 3 ) );

    for(var i=0;i<this.sources.length;i++){
      var initialIntensity = i==0 ? 0.1 : 0.0;

      // console.log(this.textures[i]);

      this.materials[i] = new THREE.ShaderMaterial( {
        uniforms: {
          "map": { value: this.textures[i] },
          "width": { value: width },
          "height": { value: height },
          "nearClipping": { value: this.nearClips[i] },
          "farClipping": { value: this.farClips[i] },
          "pointSize": { value: window.innerWidth < 800 ? 3 : 2 },
          "zOffset": { value: 600 },
          "colorIntensity" : { value: initialIntensity },
        },
        vertexShader: this.vs,
        fragmentShader: this.fs,
        blending: THREE.AdditiveBlending,
        depthTest: false, depthWrite: false,
        transparent: true
      });

      if($(this.sources[i]).parents('.pointcloud-video-element[colorIntensity]').length>0){
        let par = $(this.sources[i]).parents('.pointcloud-video-element');
        this.materials[i].uniforms.nearClipping.value = parseFloat($(par).attr('nearClipping'));
        this.materials[i].uniforms.farClipping.value = parseFloat($(par).attr('farClipping'));
        this.materials[i].uniforms.pointSize.value = parseFloat($(par).attr('pointSize'));
        this.materials[i].uniforms.zOffset.value = parseFloat($(par).attr('zOffset'));
        this.materials[i].uniforms.colorIntensity.value = parseFloat($(par).attr('colorIntensity'));
      }

      if(!this.sources[i].isVideo) {
        this.materials[i].uniforms.pointSize.value = 1;
        this.materials[i].uniforms.zOffset.value = -200;
        this.materials[i].uniforms.colorIntensity.value = 0.4;
      }

      this.meshes[i] = new THREE.Points( this.geometry, this.materials[i] );
    }

    //this.mesh = new THREE.Points( this.geometry, this.materials[0] );

    if(this.sources.length>0){
      if(this.sources[0].isVideo) {
        this.sources[0].play();
      }
    }

    this.onload();

  }

  this.onload = function(){
    // console.log('loaded');
  }

}

export { NordkappPointcloud };

global.$ = require('jquery');

import { ThreeModule } from './modules/3dmain';
global.Main = new ThreeModule();

// console.log('local override works AGAIN');

// $(window).on('load', function(){
global.loadPointcloud = function(el){
  if(!el && Main) {
    if(!Main.inited) {
      Main.init();
      // const container = document.querySelector( '#three' );
      let threeElement = Main.renderer.domElement;


      if($('.hero-work.pointcloud:not(.w-condition-invisible)').length>0){
        $('.hero-work.pointcloud').append(threeElement);
        // Main.scene.background = new THREE.Color( $('.hero-work.pointcloud').css('background-color') );
      } else if($('.pointcloud-code-wrapper').length>0){
        $('.pointcloud-code-wrapper').append(threeElement).show().css('opacity',1);
        $('.pointcloud-video-code').fadeIn(200);
      } else if($('.hero-work.video:not(.w-condition-invisible)').length>0){
        $('.hero-work.video .pointcloud-gif-container').append(threeElement).show().css('opacity',1);
        $('.hero-work.video .pointcloud-gif-container').fadeIn(200);
        $('.hero-work.video .pointcloud-gif').animate({'opacity':1},200);
      } else {
        document.body.appendChild( threeElement );
      }

      if($('.fullpage-wrapper').length>0) {
        threeElement.style.position = 'fixed';
      } else {
        threeElement.style.position = 'absolute';
      }
      threeElement.style.top = 0;
      threeElement.style.left = 0;
      threeElement.style.right = 0;
      threeElement.style.bottom = 0;
      threeElement.style.pointerEvents = 'none';
      threeElement.style.zIndex = '-1';
      // threeElement.style.opacity = '0.8';

      //container.appendChild( Main.stats.dom );
      Main.curIdx = 0;

      // document.body.appendChild( Main.stats.dom );

      $('.pointcloud-video:not(.pointcloud-code-wrapper)').css('opacity',0);

      Main.animate();

      $(window).on('resize', function(){
        Main.resize()
      });
    }
  } else if(!el) {
    console.log('no main yet');
    setTimeout(loadPointcloud, 300);
  } else if(el) {
    el.pointcloud = new ThreeModule(el, el);
    el.pointcloud.init();
    let threeElement = el.pointcloud.renderer.domElement;
    $(threeElement).insertAfter( el );

    if(!window.pointClouds) window.pointClouds = [];
    window.pointClouds.push(el.pointcloud);

    el.pointcloud.animate();

    $(el).parent().addClass('in');

    $(window).on('resize', function(){
      el.pointcloud.resize()
    });
  }
}
// });

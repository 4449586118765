global.THREE = require('three/build/three.module');

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import { AfterimagePass } from 'three/examples/jsm/postprocessing/AfterimagePass';
//  import { FocusShader } from 'three/examples/jsm/shaders/FocusShader';
import { BokehPass } from 'three/examples/jsm/postprocessing/BokehPass';
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader';
import Stats from 'three/examples/jsm/libs/stats.module.js';

// import { NordkappPoints } from './nk-points';
import { NordkappPointcloud } from './nk-pointcloud';
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min';

const clock = new THREE.Clock();

function ThreeModule(singlesource = false) {
  var self = this;
  this.camera;
  this.scene;
  this.renderer;
  this.composer;
  this.controls;
  this.mouseX = 0.5;
  this.mouseY = 0.5;
  this.curIdx = 0;
  this.prevIdx = null;
  this.runRender = true;

  this.now = Date.now();
  this.delta = Date.now();
  this.then = Date.now();
  this.interval = 1000/30; // videos play probs 30fps * 0.75 slowdown so no more is needed


  this.pointcloud;
  this.pointCloudFades = [];

  var points;
  this.stats;

  this.inited = false;

  this.init = function() {
    // camera
    this.camera = new THREE.PerspectiveCamera( 80, window.innerWidth / window.innerHeight, 0.01, 4200 );
    this.camera.position.set( 0, 0, 300);
    this.camera.updateProjectionMatrix();

    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color( 0x1A1A1A );

    if($('.hero-work.pointcloud:not(.w-condition-invisible)').length>0){
      this.scene.background = new THREE.Color( $('.hero-work.pointcloud').css('background-color') );
    } else if ($('.pointcloud-video-element').length>0) {
      if($('.pointcloud-video-element').attr('backgroundcolor')){
        this.scene.background = new THREE.Color( $('.pointcloud-video-element').attr('backgroundcolor') );
      } else {
        this.scene.background = new THREE.Color( $('.pointcloud-video-element').parent('.hero').css('background-color') );
      }
    } else if ($('.pointcloud-gif').length>0) {
      this.scene.background = new THREE.Color( $('.pointcloud-gif').css('background-color') );
    }
    // this.scene.background = new THREE.Color( 0x331A1A );
    this.scene.fog = new THREE.FogExp2( 0x1A1A1A, 0.005 );

    // The X axis is red. The Y axis is green. The Z axis is blue.
    // const axesHelper = new THREE.AxesHelper( 5 );
    // this.scene.add( axesHelper );


    let lookAt = new THREE.Vector3().copy(this.scene.position);
    this.camera.lookAt( lookAt );

    // lights
    const ambientLight = new THREE.AmbientLight( 0xFFFFFF ); // soft white light
    this.scene.add( ambientLight );

    //const pointLight = new THREE.PointLight( 0xFFFFFF, 2, 8000 );
    //pointLight.position.set( 0, 800, 3000 );
    //this.scene.add( pointLight );


    // renderer
    this.renderer = new THREE.WebGLRenderer({
      antialias: false,
      powerPreference: 'low-power',
      precision: 'lowp',
    });
    this.renderer.setClearColor( new THREE.Color( 0x000000 ) );
    var renderResolution = window.devicePixelRatio;
    /*
    if(window.innerWidth < 800 && window.innerWidth < window.innerHeight){
      renderResolution = 1;
    }
    */
    this.renderer.setPixelRatio( renderResolution );
    // this.renderer.setPixelRatio( window.devicePixelRatio );
    this.renderer.setSize( window.innerWidth, window.innerHeight );
    // this.renderer.autoClear = true;

    // postprocessing
    this.composer = new EffectComposer( this.renderer );

    const renderClean = new RenderPass( this.scene, this.camera );
    this.composer.addPass( renderClean );


    /*
    const fxaaPass = new ShaderPass( FXAAShader );
    const pixelRatio = this.renderer.getPixelRatio();
    fxaaPass.material.uniforms[ 'resolution' ].value.x = 1 / ( window.innerWidth * pixelRatio );
    fxaaPass.material.uniforms[ 'resolution' ].value.y = 1 / ( window.innerHeight * pixelRatio );
    this.composer.addPass( fxaaPass );
    */

    //const bloomPass = new UnrealBloomPass( new THREE.Vector2( window.innerWidth, window.innerHeight ), 1.5, 0.4, 0.85 );
    //bloomPass.threshold = 0.24; //0.24;
    //bloomPass.strength = 0.66; //0.8;
    //bloomPass.radius = 0.05; // 0.00002;
    //this.composer.addPass( bloomPass );

    /*
    this.bokehPass = new BokehPass( this.scene, this.camera, {
      focus: 300.0,
      aperture: 0.5,
      maxblur: 0.01,
      width: window.innerWidth,
      height: window.innerHeight
    } );
    this.composer.addPass( this.bokehPass );
    */

    //this.afterimagePass = new AfterimagePass();
    //this.afterimagePass.uniforms.damp.value = 0.5;
    //this.composer.addPass( this.afterimagePass );

    // objects
    this.pointcloud = new NordkappPointcloud(this, singlesource);

    this.pointcloud.onload = function(){
      // console.log('overridden pointcloud load');
      for(var i=0;i<this.meshes.length;i++){
        self.scene.add(this.meshes[i]);
      }
    }
    this.pointcloud.init();
    for(var i=0;i<this.pointcloud.meshes.length;i++){
      this.pointcloud.meshes[i].scale.copy(new THREE.Vector3(3, 3, 1.0));
    }
    //pointcloud.mesh.position.z = 500;
    //pointcloud.mesh.position.y += 500;

    /*
    points = new NordkappPoints(this);

    points.planeLoaded = function(){
      let vertexPlane = this.vertexPlane;
      self.scene.add(vertexPlane);
      let vertexPlaneWire = this.vertexPlaneWire;
      self.scene.add(vertexPlaneWire);
      console.log('yep it loaded!');
      this.randomizeVertexPlane();
    }
    points.createVertexPlane();

    points.loaded = function(){
      let waves = this.pointClouds[0];
      waves.rotateX(Math.PI * -0.3);
      waves.rotateZ(Math.PI * 0.1);
      waves.position.y = 20;
      self.scene.add(waves);
      console.log(waves);
      // console.log(this, self);
      console.log('yep it loaded!');
    }
    */
    //points.createPoints();

    this.stats = new Stats();

    this.inited = true;

  }


  this.updateActive = function(idx) {
    self.prevIdx = self.curIdx;


    for(var i=0;i<self.pointcloud.sources.length;i++){
      if(i==self.prevIdx){
        let from = {
          index: i,
          intensity: self.pointcloud.materials[i].uniforms.colorIntensity.value,
          zOffset: 600,
        };

        let to = {
          index: i,
          intensity: 0.0,
          zOffset: 300,
        };

        if(this.pointCloudFades[i]){
          TWEEN.remove(this.pointCloudFades[i]);
        }
        this.pointCloudFades[i] = new TWEEN.Tween(from)
        .to(to, 1000)
        .easing(TWEEN.Easing.Cubic.Out)
        .onUpdate((obj) => {
          // console.log('tweening');
          self.pointcloud.materials[obj.index].uniforms.zOffset.value = obj.zOffset;
          self.pointcloud.materials[obj.index].uniforms.colorIntensity.value = obj.intensity;
        })
        .start()
        .onComplete((obj) => {
          if(self.curIdx !== obj.index){
            self.pointcloud.sources[obj.index].pause();
          }
          this.pointCloudFades[obj.index] = null;
        });
      }

      if(i == idx) {
        self.curIdx = idx;
        self.pointcloud.sources[i].play();

        // Fade in video
        let from = {
          index: i,
          intensity: self.pointcloud.materials[i].uniforms.colorIntensity.value,
          zOffset: 300,
        };

        let to = {
          index: i,
          intensity: 0.1,
          zOffset: 600,
        };

        if(this.pointCloudFades[i]){
          TWEEN.remove(this.pointCloudFades[i]);
        }

        this.pointCloudFades[i] = new TWEEN.Tween(from)
        .to(to, 1000)
        .easing(TWEEN.Easing.Cubic.Out)
        .onUpdate((obj) => {
          self.pointcloud.materials[obj.index].uniforms.zOffset.value = obj.zOffset;
          self.pointcloud.materials[obj.index].uniforms.colorIntensity.value = obj.intensity;
          this.pointCloudFades[obj.index] = null;
        })
        .start()
        .onComplete(() => {
          // self.afterimagePass.uniforms.damp.value = 0.8;
        });
      }
    }

    /*
    if(this.pointcloud.materials[idx]) {
      this.pointcloud.mesh.material = this.pointcloud.materials[idx];
      //console.log(this.pointcloud.textures[idx]);
      this.pointcloud.mesh.material.needsUpdate = true;
    }
    */

    if(idx==1){
      var newRot = new THREE.Vector3().copy(this.scene.rotation);
      //newRot.x = THREE.MathUtils.degToRad(-20);
      newRot.z = THREE.MathUtils.degToRad(20);
      var meshRotTween = new TWEEN.Tween(this.scene.rotation)
      .to(newRot, 3000)
      .easing(TWEEN.Easing.Cubic.Out)
      .start();
      //this.scene.rotation.x = THREE.MathUtils.degToRad(-20);
    } else {
      var newRot = new THREE.Vector3(0, 0, 0);
      var meshRotTween = new TWEEN.Tween(this.scene.rotation)
      .to(newRot, 3000)
      .easing(TWEEN.Easing.Cubic.Out)
      .start();
    }
  }

  this.resize = function(){
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    // this.camera.lookAt( this.scene.position );
    this.renderer.setSize( window.innerWidth, window.innerHeight );
    this.composer.setSize( window.innerWidth, window.innerHeight );
    // this.effectFocus.uniforms[ "screenWidth" ].value = window.innerWidth * window.devicePixelRatio;
    // this.effectFocus.uniforms[ "screenHeight" ].value = window.innerHeight * window.devicePixelRatio;
  }


  this.animate = function() {
    requestAnimationFrame( self.animate );
    // console.log('animating');
    if(self.runRender){
      self.now = Date.now();
      self.delta = self.now - self.then;

      if (self.delta > self.interval) {
        if(TWEEN) TWEEN.update();
        self.render();
        self.then = self.now - (self.delta % self.interval);
      }

      if(self.stats) {
        self.stats.update();
      }
    }
  }

  this.render = function() {
    let delta = clock.getDelta();

    if(points && points.vertexPlane && points.planeCanAnimate){
      //console.log('u have it');
      points.animatePlaneWave(delta);
    }

    if(points && points.pointClouds.length>0) {
      let cloud = points.pointClouds[0];
      points.animateWave(cloud, delta);
    }


    this.camera.position.x += ( this.mouseX - this.camera.position.x ) * .025;
    if(points && points.vertexPlane){
      points.vertexPlane.rotation.x = ( this.mouseY - points.vertexPlane.rotation.x ) * .005;
      points.vertexPlane.rotation.y = ( this.mouseX - points.vertexPlane.rotation.y ) * .005;

      points.vertexPlaneWire.rotation.x = ( this.mouseY - points.vertexPlaneWire.rotation.x ) * .005;
      points.vertexPlaneWire.rotation.y = ( this.mouseX - points.vertexPlaneWire.rotation.y ) * .005;
    }
    //this.camera.position.z += ( this.mouseX - this.camera.position.z ) * .05;
    this.camera.position.y += (( - this.mouseY - (this.camera.position.y) ) * .025);
    //this.camera.position.z = (( - this.mouseY - this.camera.position.y ) * .025);
    this.camera.lookAt(new THREE.Vector3(0, 0, 0));
    this.composer.render( 0.01 );
  }

}
export { ThreeModule };
